import React from "react"
import { graphql } from "gatsby"
import Image from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col } from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import RecipeCard from '../components/recipe-card'

class PrivacyPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Gizlilik Sözleşmesi" />

        <div className="container">

          <Row className="recipe-detail-row mb-5 mt-5">

            <Col md="12">
              <h3>Gizlilik Politikası</h3>
              <p>
                www.pratiksoslar.com olarak kişisel gizlilik haklarınıza saygı duyuyor ve sitemizde geçirdiğiniz süre zarfında bunu sağlamak için çaba sarfediyoruz. Kişisel bilgilerinizin güvenliği ile ilgili tanımlar aşağıda açıklanmış ve bilginize sunulmuştur. Sözleşme hakkında sorularınız olursa lütfen mail adresine mail atmaktan çekinmeyiniz. www.pratiksoslar.com olarak ziyaretçilerimizin gizliliği konusu bizim önceliklerimizden biridir.
                Kişisel Bilgileriniz
                Birçok standart web sunucusunda olduğu gibi www.pratiksoslar.com da istatistiksel amaçlı log dosyaları kaydı tutmaktadır. Bu dosyalar; ip adresiniz, internet servis sağlayıcınız, tarayıcınızın özellikleri, işletim sisteminiz ve siteye giriş – çıkış sayfalarınız gibi standart bilgileri içermektedir. Log dosyaları kesinlikle istatistiksel amaçlar dışında kullanılmamakta ve mahremiyetinizi ihlal etmemektedir. Ip adresiniz ve diğer bilgiler, şahsi bilgileriniz ile ilişkilendirilmemektedir.
              </p>
                <p>Çerezler
                “Cookie – çerez” kelimesi web sayfası sunucusunun sizin bilgisayarınızın hard diskine yerleştirdiği ufak bir text dosyasını tanımlamak için kullanılmaktadır. Sitemizin bazı bölümlerinde kullanıcı kolaylığı sağlamak için çerez kullanılıyor olabilir. Ayrıca sitede mevcut bulunan reklamlar aracılığıyla, reklam verilerinin toplanması için cookie ve web beacon kullanılıyor olabilir. Bazı reklamveren ortakları çerezlerini ve Web işaretleyicilerini kullanabilir. Bu reklamveren ortakları arasında Google Adsense de vardır. Bu bize bağlı olmayan sunucular ve reklam ağları kendi teknolojilerini www.pratiksoslar.com'u kullanarak tarayıcınıza bilgileri gönderebilir. Bu tamamen sizin izninizle gerçekleşiyor olup, isteğiniz dahilinde internet tarayıcınızın ayarlarını değiştirerek bunu engellemeniz mümkündür.
                www.pratiksoslar.com reklam şirketlerinin çerezleri üzerinde herhangi bir kontrolü yoktur Bu konuda daha detaylı bilgi için reklamveren ortaklarımıza başvurabilirsiniz yada yukarıda belirtmiş olduğumuz mail adresinden iletişime geçerek, reklamveren ortaklarımıza ulaşma anlamında yardım isteyebilirsiniz. www.pratiksoslar.com bildiriminde anlatıldığı gibi reklamveren ortaklarımızın uygulamaları üzerinde bir kontrolümüz yoktur.
                Eğer çerezlerinizi iptal etmek istiyorsanız tarayıcı seçeneklerinizden ayarlayabilirsiniz. Daha detaylı bilgi için tarayıcınızın yardım dosyalarını inceleyiniz.
                </p>
              <p>Çocukların Gizliliği
                www.pratiksoslar.com 18 yaşın altındaki kimseye hitap etmemektedir. 18 yaşın altındaki çocuklardan bilerek kişisel olarak tanımlanabilir bilgi toplamıyoruz. 18 yaşın altındaki bir çocuğun bize kişisel bilgi sağladığını tespit ettiğimizde, bunu derhal sunucularımızdan siliyoruz. Ebeveyn iseniz ve çocuğunuzun bize kişisel bilgiler sağladığını düşünüyorsanız, gerekli işlemleri yapabilmemiz için lütfen bizimle iletişime geçin .

                Dış Bağlantılar
                www.pratiksoslar.com sitesi, internetin doğası gereği birçok farklı internet adresine bağlantı vermektedir. www.pratiksoslar.com link verdiği, banner tanıtımını yaptığı sitelerin içeriklerinden veya gizlilik prensiplerinden sorumlu değildir. Burada bahsedilen bağlantı verme işlemi, hukuki olarak “atıfta bulunma” olarak değerlendirilmektedir.
              </p>
                <p>Gizlilik Politikasında yapılabilecek değişiklikler

                www.pratiksoslar.com olarak bu sayfada yer alan gizlilik politikasını değiştirebilir veya yeni maddeler ekleyebiliriz. Bu nedenle bu sayfayı düzenli olarak incelemenizi tavsiye ederiz.
                </p>
                  <p>İletişim
                Gizlilik politikamızda yer alan maddeler ile ilgili soru veya önerileriniz var ise lütfen bizimle iletişime geçmekten çekinmeyin. Bize iletişim sayfamızı kullanarak veya aşağıdaki iletişim formunu doldurarak ulaşabilirsiniz.

                İletişim için cemerdurak469((at))gmail.com</p>

              <p>Belki bize söylemek istediğiniz bir şeyler vardır. </p>
              <p><a class="btn btn-primary push-right" href="/contact">İletişim</a></p>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5 pt-5">
              <p className="text-center">Belki lezzetli sos tariflerimize bakmak istersiniz:</p>

              <Row className="hp-recipes">
              {posts.map(({ node }) => {
                  return (
                    <Col md="4">
                      <RecipeCard recipe={node} />
                    </Col>
                  )
                })}
                </Row>
              </Col>

          </Row>

        </div>
      </Layout>
    )
  }
}

export default PrivacyPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    aboutImage: file(absolutePath: { regex: "/about-us-@2x.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3 
    ){
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            prep
            cook
            thumbnail{
              childImageSharp{
                fluid(maxWidth:500, quality:80){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    tags: allMarkdownRemark(limit: 2000){
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`
